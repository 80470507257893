<template>
    <div class="block_photo_card">
        <div class="info-news">
            <tabs-header class="card_containet__tabs" :section="electionInfo" />
            <tricolor-line class="tricolor_line"/>
            <div v-if="getBanners && getBanners.length" v-html="getBanners[0].top_banner" class="wysiwyg">
            </div>
            <!--<div class="info-news__icon-title">
                <figure class="info-news__icon" :style="`background: url('${require('/assets/icons/' + electionInfo.icon)}') center / cover`"></figure>
                <h5>Идет набор заявок</h5>
            </div>
            <p>Идет набор в общественные советы при: </p>
            <ul>
                <li v-for="(item, i) in electionInfo.templated" :key="`list${i}`"><p>{{item.title}}</p></li>
            </ul>-->
        </div>
        <div class="news__important-container">
            <tabs-header class="card_containet__tabs" :section="news" />
            <tricolor-line class="tricolor_line"/>
            <router-link class="importan-content" :to="`news/${card.id}`" v-for="(card, i) in getNews" :key="`news${i}`">
                <figure :style="`background: url('${card.preview_photo}') center / cover`"></figure>
                <article>
                    <p class="importan-content__date">{{ dateArr(card.date_time) }}</p>
                    <p v-trim="3" class="importan-content__title">{{ card.title }}</p>
                    <span v-if="card.infoTime">{{card.infoTime}}</span>
                </article>
            </router-link>
            <pagination class="news__pagination"
                        v-if="false"
                        :per-page="$store.getters.getPaginationLimit"
                        :current="$store.getters.getPaginationCurrentPage"
                        :total="$store.getters.getPaginationTotal"
                        @page-changed="getPage"/>
        </div>
    </div>
</template>
<script>
import TricolorLine from '@/components/TricolorLine'
import TabsHeader from '@/components/indexPage/TabsHeader'
import Pagination from '../Pagination'
export default {
  name: 'ElectionInfo',
  components: {
    Pagination,
    TricolorLine,
    TabsHeader
  },
  data () {
    return {
      electionInfo: {
        title: 'Текущая информация о выборах в ОС',
        url_link: '/community_councils?tab=0',
        url_name: 'Формирование ОС',
        icon: 'election.svg',
        templated: [
          { title: 'Федеральной службе по регулированию алкогольного рынка;' },
          { title: 'Федеральном агентстве по рыболовству;' },
          { title: 'Федеральной службе по экологическому, технологическому и атомному надзору; ' },
          { title: 'Федеральной антимонопольной службе;' },
          { title: 'Федеральной пробирной палате' }
        ]
      },
      news: {
        title: 'Последние новости',
        url_link: '/news?tab=News',
        url_name: 'Посмотреть все'
        // templated: [
        //   {
        //     date: '11.08.2020',
        //     title: 'Трансляция с выборов',
        //     subtitle: 'Трансляция с выборов',
        //     photo: 'stream1.png',
        //     infoTime: 'Начало в 20:20'
        //   },
        //   {
        //     date: '11.08.2020',
        //     title: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
        //     subtitle: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
        //     photo: 'news14.png'
        //   },
        //   {
        //     date: '11.08.2020',
        //     title: 'Экоферма в Алтайском крае под угрозой из-за перекрытой дачниками дороги',
        //     subtitle: 'Экоферма в Алтайском крае под угрозой из-за перекрытой дачниками дороги',
        //     photo: 'news2.png'
        //   }
        // ]
      }
    }
  },
  mounted () {
    this.getData()
  },

  beforeDestroy () {
    this.$store.commit('setPaginationLimit', 9)
    this.$store.commit('setPaginationOffset', 0)
  },

  computed: {
    getNews () {
      return this.$store.getters.getPublicNews ? this.$store.getters.getPublicNews.slice(0, 3) : []
    },
    getBanners () {
      return this.$store.getters.getBanners
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    },

    getPage (page) {
      this.$store.commit('setIsSearch', true)
      this.$store.commit('setPaginationOffset', (page - 1) * this.$store.getters.getPaginationLimit)
      this.getData()
    },

    getData (isSearch) {
      this.$store.commit('setFilterSystemTagList', 'public_councils')

      this.$store.commit('setPaginationLimit', 9)

      this.$store.dispatch('getPublicNews', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.block_photo_card{
    display: flex;
}

.card_containet__tabs{
    margin-top: 1.44rem;
}
.tricolor_line{
    margin: 1rem 0 1.25rem 0;
}

h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.info-news {
  // width: 26.56rem;
  // height: 23.44rem;
  flex: auto;
  margin-right: 2.125rem;

  display: flex;
  flex-direction: column;
  &__icon-title{
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
  }
  &__icon {
    width: 2.813rem;
    height: 2.813rem;
    border-radius: 50%;
    margin-right: 1.25rem;
  }
  p{
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #000000;
  }
  ul li{
    list-style-type: disc;
    margin-top: .625rem;
    padding-left: 0.75rem;
    margin-left: 1.35rem;
  }
  li::marker {
    width: 0.25rem;
    height: 0.25rem;
    color: #246CB7;
  }
  .button{
    width: 10.125rem;
    font-weight: 600;
    font-size: .88rem;
    line-height: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    border: 0;
    background: #246CB7;
    border-radius: .19rem;
    padding: 0.5rem 0;
    margin-top: 1.88rem;
  }

}
.news__important-container{
    width: 26.625rem;
    flex-direction: column;
    flex-wrap: nowrap;
}
.importan-content {
    width: 100%;
    display: flex;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: .063rem solid #eee;
    cursor: pointer;

    figure {
      width: 8.4375rem;
      height: 5.625rem;
      border-radius: 0.5rem;
      flex-shrink: 0;
      margin-right: 1.25rem;
    }
    article {
      width: auto;
      font-size: 0.875rem;
      line-height: 157%;
    }

    &__date {
      color: #5E686C;
      opacity: 0.8;
    }

    &__title {
      font-weight: 600;
      color: #1F3245;
      margin: .313rem 0;
    }
    span{
      background: #8ABD1F;
      font-weight: 600;
      font-size: .813rem;
      line-height: 1rem;
      color: #FFFFFF;
      padding: .125rem .63rem;
      border-radius: .19rem;
    }

  }
  .importan-content:last-child{
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

.wysiwyg::v-deep {
    h5 {
        font-weight: 600;
        font-size: 1.38rem;
        line-height: 1.75rem;
        color: #1f3245;
        margin-bottom: 1.25rem;
        > img {
            margin-right: 1.25rem !important;
        }
    }

    p {
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #000;
    }

    ul {
        li {
            list-style-type: disc;
            margin-top: .625rem;
            padding-left: .75rem;
            margin-left: 1.35rem;
            &::marker {
                position: relative;
                width: .25rem;
                height: .25rem;
                color: #246cb7;
            }
        }
    }

    .button {
        display: block;
        width: 10.125rem;
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.25rem;
        text-align: center;
        color: #FFFFFF;
        border: 0;
        background: #246CB7;
        border-radius: .19rem;
        padding: 0.5rem 0;
        margin-top: 1.88rem;
    }
}

@media screen and (max-width: 768px){
  .info-news {
    width: 20.56rem;
    ul li{
      font-size: 1rem;
    }
 }
  .news__important-container {
    display: none;
  }
}
@media screen and (max-width: 420px){
  .info-news {
    /*width: 22.9rem;*/
    scroll-snap-align: start;
    flex-shrink: 0;
  }
}
</style>
