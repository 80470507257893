<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Общественные советы при ФОИВ</h5>
            <election-info/>
        </div>
        <div class="white-container general_info">
            <div v-if="getBanners && getBanners.length" v-html="getBanners[0].bottom_banner" class="wysiwyg">
            </div>
            <!--<h6>Общая информация</h6>
            <p>Общественная палата в соответствии с законодательством Российской Федерации принимает участие в формировании общественных советов при федеральных органах исполнительной власти.
                <br><br>
                Общественные советы при федеральных органах исполнительной власти формируются на конкурсной основе, если иной порядок формирования общественных советов при отдельных федеральных органах исполнительной власти не предусмотрен нормативными правовыми актами Президента Российской Федерации или Правительства Российской Федерации. Организатором конкурса является Общественная палата Российской Федерации.
                <br><br>
                Общественный совет при федеральном органе исполнительной власти призван обеспечить учет потребностей и интересов граждан Российской Федерации, защиту прав и свобод граждан Российской Федерации и прав общественных объединений при осуществлении государственной политики в части, относящейся к сфере деятельности органа исполнительной власти, а также в целях осуществления общественного контроля за деятельностью соответствующего федерального органа исполнительной власти.
                <br><br>
                Общественный совет является постоянно действующим совещательно-консультативным органом общественного контроля.
                Целью деятельности общественного совета является осуществление общественного контроля за деятельностью федерального органа исполнительной власти, включая рассмотрение проектов разрабатываемых общественно значимых нормативных правовых актов, участие в мониторинге качества оказания государственных услуг, реализации контрольно-надзорных функций, хода проведения антикоррупционной и кадровой работы, оценке эффективности государственных закупок, рассмотрение ежегодных планов деятельности органа власти и отчета об их исполнении, а также иных вопросов, предусмотренных действующим законодательством.
                <br><br>
              Срок полномочий членов общественного совета составляет три года с момента проведения первого заседания общественного совета вновь сформированного состава.
            </p>-->
            <h6>Войдите или зарегистрируйтесь в личном кабинете кандидата</h6>
            <div class="general_info__link">
              <a target="_blank" href="https://os.oprf.ru/cabinet/registration/" class="pesonal_account">Войти в личный кабинет</a>
              <a target="_blank" href="https://os.oprf.ru/cabinet/registration/">Зарегистрироваться</a>
            </div>
        </div>
        <div class="block_sections">
            <div class="white-container">
              <h6>Кандидату в Общественный совет</h6>
              <router-link to="/community_councils?tab=1">Перейти</router-link>
            </div>
            <div class="white-container">
                <h6>Документы</h6>
                <router-link to="/community_councils?tab=2">Перейти</router-link>
            </div>
        </div>
        <div class="white-container ask_question">
            <p>Часто задаваемые вопросы</p>
            <router-link to="/public_councils_faq">Перейти</router-link>
        </div>
        <div v-show="!!isActiveAuth" class="main-search__background">
          <v-auth @click="isActiveAuth = false"/>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import ElectionInfo from '@/components/activitiesSurveys/ElectionInfo'
import VAuth from '@/components/header/VAuth'
export default {
  name: 'PublicCouncils',
  components: {
    BreadCrumb,
    ElectionInfo,
    VAuth
  },
  data () {
    return {
      isActiveAuth: false,
      currentPage: 1,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные советы'
        }
      ]
    }
  },
  mounted () {
    document.title = 'Общественные советы при ФОИВ'
  },
  created () {
    this.$store.dispatch('setBanners')
  },
  computed: {
    getBanners () {
      return this.$store.getters.getBanners
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
h6{
  font-weight: 600;
  font-size: 1.13rem;
  line-height: 1.5rem;
  color: #1F3245;
  margin-bottom: 1.25rem;
}
a,.pesonal_account{
  font-weight: bold;
  font-size: .88rem;
  line-height: 1.25rem;
  text-align: center;
  color: #FFFFFF !important;

  background: #246CB7;
  border-radius: .19rem;
  padding: .5rem 1.44rem .44rem 1.44rem;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.general_info{
  margin-top: 3.13rem;
  background: #ffffff url('../../assets/bg/oprf_background.svg') 83% center no-repeat;
  background-size: contain;
  p{
    margin-bottom: 2.5rem;
    font-size: .835rem;
    line-height: 1.38rem;
    color: rgba(23, 23, 23, 0.8);
  }
  &__link{
    display: flex;
    a:last-child{
      background: #E9242A;
      margin-left: 1.25rem;
    }
  }
}
.block_sections{
  width: auto;
  height: 12.5rem;
  margin: 3.125rem 5rem 0 5rem;
  display: flex;
  justify-content: space-between;
  .white-container{
    width: 43.88rem;
    margin: 0;
  }
  .white-container:first-child{
    background: #ffffff url('../../assets/bg/search_computer.png') 90% bottom no-repeat;
    background-size: 16.94rem;
  }
  .white-container:last-child{
    background: #ffffff url('../../assets/bg/documents.png') 90% bottom no-repeat;
    background-size: 16.44rem;
  }
}
.ask_question{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 2.94rem;
  margin-top: 3.125rem;
  p{
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1F3245;
    opacity: 0.9;
    padding-left: 4.07rem;

    position: relative;
  }
  p:after{
    content: '';
    width: 2.813rem;
    height: 2.813rem;
    background: url('../../assets/icons/ask_question.svg') center center no-repeat;
    background-size: contain;
    padding: .72rem 1.41rem;

    position: absolute;
    top: -45%;
    left: 0;
  }
}
.main-search__background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  background: rgba(32, 51, 70, 0.33);
  z-index: 3;
}
.wysiwyg::v-deep {
    margin-bottom: 2.5rem;
    font-size: .835rem;
    line-height: 1.38rem;
    color: hsla(0,0%,9%,.8);
    h6 {
        font-weight: 600;
        font-size: 1.13rem;
        line-height: 1.5rem;
        color: #1f3245;
        margin-bottom: 1.25rem;
    }
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem 1rem .75rem;
    padding: 1.86rem 1.25rem;
  }
  .block_sections{
    flex-direction: column;
    width: auto;
    margin: 0 .75rem 5rem .75rem;
    >.white-container{
      width: 100%;
    }
    >.white-container:first-child{
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .general_info__link{
        flex-direction: column;
        a{
          width: 100%;
        }
        a:last-child{
          margin: 1rem 0 0 0;
        }
    }
    .block_sections{
      margin: 0 0 5rem 0;
      .white-container:first-child,
      .white-container:last-child{
        background-size: 9rem;
      }
    }
    .ask_question p:after{
      top: 0.05rem;
    }
}

</style>
